import React, { useEffect } from 'react';
import ShowSharedStyles from './ShowSharedStyles';
import ContactButton from 'components/ContactButton/ContactButton';
import Background from 'components/Background/Background';

export default function ShowPremium(props) {
   const classes = ShowSharedStyles()
   
   useEffect(() => {
      document.title = props.title;
   });
   
   return (
      <div className={classes.ShowTypePage}>
         <Background />
         <div className={classes.showBody}>
            <ul className={classes.imageList}>
               <li className={classes.premium1}></li>
               <li className={classes.premium2}></li>
               <li className={classes.premium3}></li>
            </ul>
            <h1>Prémium előadás</h1>
            <p>Ez a műsor a legnagyobb lángokat és a legvarázslatosabb atmoszférát teremti meg. A dinamikus produkció célja a közönség elkápráztatása és az izgalom fokozása a végletekig. A világ számos részén már egyre ritkábban látott veszélyes show elem is felbukkan, ami nem más mint a tűzfújás. Garantált 3 tűzfújás szerepel az előadásban, melyet sokan pont a veszélyei miatt nem vállalnak, mivel helytelen használat során, könnyen károsíthatja a szervezetet.</p>
            <p>Mindemellett számos különleges tűzzsonglőr eszköz jelenik meg, mely a Standard előadásban nem. Ezek a hatalmasan lángoló tárgyak magasabb szintű tudást és tehetséget igényelnek, melyeket még az országban senki, vagy csak kevesen használnak. Ezek közül ki is emelnék párat: lángoló ostor, lángoló angyalbot, lángoló kocka.</p>
            <p>A műsorszám alatt a közönségnek is lehetősége van közelebbről megismerkedni a tűzzel, interaktív módon, mégis biztonságos kereteken belül. A program garantáltan minőségi szórakozást nyújt minden korosztálynak, a gyerekektől az idősekig.</p>
            <p>Ezt az előadást ajánlom figyelmébe amennyiben igazán nagyszabású programot tervez és a legnagyobb mértékben szeretné lenyűgözni a közönséget. Akár záró produkcióként is egy hatalmas élménnyel gazdagodik a nagyérdemű.</p>
            <p>A többi produkcióval ellentétben nincs külön tematikája, úgymond univerzális, ezért szinte minden rendezvényhez jól illik.</p>
            <h2>Időtartam: 30 perc </h2>
            <div className={classes.list}>
               <h3>Műsoromat ajánlom:</h3>
               <ul>
                  <li>Fesztiválokra, koncertekre</li>
                  <li>Város- és falunapokra</li>
                  <li>Művészi Gálákra- Díjátadókra</li>
                  <li>Céges és magán rendezvényekre</li>
                  <li>Esküvőkre</li>
                  <li>Lány- és legénybúcsúkra</li>
                  <li>Iskolai és óvodai rendezvényekre</li>
                  <li>Vásári forgatagokra és bálokra</li>
               </ul>
            </div>
            <div className={classes.list}>
               <h3>TECHNIKAI FELTÉTELEK:</h3>
               <ul>
                  <li>Legalább 6m X 6m-es tiszta akadálymentes, csúszásmentes terület</li>
                  <li>Kihangosítás az aláfestő zenéhez (nagyteljesítményű aktív hangfal, mp3 lejátszó)</li>
                  <li>Zárható öltöző tisztálkodási lehetőséggel</li>
                  <li>Épületben (bel térben) tartandó rendezvény esetén:
                     <ul>
                        <li>Minimum 6m belmagasság</li>
                        <li>Folyamatos szellőztetés, elszívó rendszer bekapcsolása</li>
                        <li>Tűzjelzők és füstérzékelők kikapcsolása a műsor ideje alatt és az azt követő 10 percben</li>
                     </ul>
                  </li>
               </ul>
            </div>
            <div className={classes.list}>
               <h3>AZ ELŐADÁSHOZ RENDELHETŐ FELÁRAS SZOLGÁLTATÁSAINK:</h3>
               <ul>
                  <li>Hangtechnika (1 db aktív hangfal, állványra telepítve)</li>
                  <li>Lángoló betű, szám kiírás (Talajra rajzolt lángoló betű, szám, egyszerű motívum, csak kültérre!)</li>
               </ul>
            </div>
            <ContactButton />
         </div>
      </div>
   )
}
import React from 'react';
import FooterStyles from './FooterStyles';
import logo from 'assets/images/icons/logo-footer.png';
import facebook from 'assets/images/icons/facebook.png';
import insta from 'assets/images/icons/insta.png';
import twitter from 'assets/images/icons/twitter.png';
import youtube from 'assets/images/icons/youtube.png';

export default function Footer(props){
   const classes = FooterStyles()
   return (
      <div className={classes.Footer}>
         <img className={classes.logo} src={logo} alt="tuzidomar" />
         <h1 className={classes.name}>Tierra Jimmy</h1>
         <hr className={classes.hr}/>
         <div className={classes.links}>
            <a href="https://www.facebook.com/tuzidomar/" target="_blank" rel="noopener noreferrer">
               <img src={facebook} alt="facebook" />
            </a>
            <a href="https://www.instagram.com/tierra_jimmy/" target="_blank" rel="noopener noreferrer">
               <img src={insta} alt="instagram" />
            </a>
            <a href="https://twitter.com/tuzidomar" target="_blank" rel="noopener noreferrer">
               <img src={twitter} alt="twitter" />
            </a>
            <a href="https://www.youtube.com/channel/UCqGBTXLFgysm1YqXBgKFTEg" target="_blank" rel="noopener noreferrer">
               <img src={youtube} alt="youtube" />
            </a>
         </div>
      </div>
   )
}
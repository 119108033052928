import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import SideNavStyles from './SideNavStyles';

export default function SideNav(props) {
   const classes = SideNavStyles()
   
   return (
      <section>
         <nav className={clsx(classes.SideNav, {[classes.openSideNav]: props.sideNavActive})}>
            <ul className={classes.navList}>
               <li onClick={props.handleToggle}>
                  <Link to='/'>KEZDŐLAP</Link>
               </li>
               <li onClick={props.handleToggle}>
                  <Link to='/rolam'>RÓLAM</Link>
               </li>
               <li onClick={props.handleToggle}>
                  <Link to='/eloadasok'>ELŐADÁSOK</Link>
               </li>
               <li onClick={props.handleToggle}>
                  <Link to='/galeria'>GALÉRIA</Link>
               </li>
               <li onClick={props.handleToggle}>
                  <Link to='/kapcsolat'>KAPCSOLAT</Link>
               </li>
            </ul>
         </nav>
      </section>
   )
}
import { makeStyles } from '@material-ui/core/styles';
import sizes from 'sizes';
import showcase1 from 'assets/images/showcase/showcase-1.jpg';
import showcase2 from 'assets/images/showcase/showcase-2.jpg';
import showcase3 from 'assets/images/showcase/showcase-3.jpg';
import showcase4 from 'assets/images/showcase/showcase-4.jpg';
import './ShowcaseAnimation.css';

export default makeStyles({
   Showcase: {
      width: '100%',
      height: '700px',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '105px',
      [sizes.down('lg')]: {
         height: '600px',
         marginTop: '45px'
      },
      [sizes.down('md')]: {
         height: '500px'
      }
   },
   header: {
      width: '50%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: 'transparent',
      color: 'white',
      letterSpacing: '4px',
      textTransform: 'uppercase',
      position: 'absolute',
      zIndex: '4',
      '& h1': {
         fontSize: '1.5rem',
         fontWeight: '700',
         lineHeight: '40px'
      },
      '& h2': {
         fontSize: '1.1rem',
         fontWeight: '700',
         color: '#FFC300'
      },
      [sizes.down('lg')]: {
         width: '60%'
      },
      [sizes.down('md')]: {
         width: '70%'
      },
      [sizes.down('xs')]: {
         justifyContent: 'center',
         textAlign: 'center'
      }
   },
   links: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '125px',
      '& img': {
         height: '45px',
         width: '45px'
      },
      [sizes.down('md')]: {
         width: '110px'
      },
      [sizes.down('xs')]: {
         display: 'none'
      }
   },
   list: {
      width: '100%',
      height: '100%',
      margin: '0',
      padding: '0',
      listStyle: 'none',
      backgroundColor: 'rgba(0,0,0,0.8)',
      '& li': {
         width: '100%',
         height: '100%',
         position: 'absolute',
         top: '0',
         left: '0',
         backgroundSize: 'cover',
         backgroundPosition: '50% 50%',
         backgroundRepeat: 'no-repeat',
         opacity: '0',
         animation: 'ShowcaseAnimation 40s linear infinite'
      },
      '& li:nth-child(1)': { 
         backgroundImage: `url(${showcase1})`
      },
      '& li:nth-child(2)': { 
         backgroundImage: `url(${showcase2})`,
         animationDelay: '10s'
      },
      '& li:nth-child(3)': { 
         backgroundImage: `url(${showcase3})`,
         animationDelay: '20s'
      },
      '& li:nth-child(4)': { 
         backgroundImage: `url(${showcase4})`,
         animationDelay: '30s'
      }
   },
   overlay: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: '0',
      left: '0',
      zIndex: '3',
      backgroundColor: '#202020',
      opacity: '0.70'
   }
})
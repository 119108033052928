import React, { useEffect, useState } from "react";
import ContactStyles from "./ContactStyles";
import facebook from "assets/images/icons/facebook.png";
import insta from "assets/images/icons/insta.png";
import twitter from "assets/images/icons/twitter.png";
import youtube from "assets/images/icons/youtube.png";
import phone from "assets/images/icons/phone.png";
import mail from "assets/images/icons/mail.png";
import ContactForm from "components/ContactForm/ContactForm";
import Background from "components/Background/Background";
import YouTubeVideo from "components/YouTubeVideo/YouTubeVideo";
import HorizontalRule from "components/HorizontalRule/HorizontalRule";
import FormSent from "components/FormSent/FormSent";
const Fragment = React.Fragment;

export default function Contact(props) {
  const classes = ContactStyles();
  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    document.title = props.title;
  });

  const sentMail = () => {
    setIsSent(!isSent);
  };

  let formSpace;
  if (!isSent) {
    formSpace = <ContactForm sentMail={sentMail} />;
  } else {
    formSpace = <FormSent />;
  }

  return (
    <div className={classes.Contact}>
      <Background />
      <div className={classes.information}>
        {!isSent && (
          <Fragment>
            <div className={classes.contactInstructions}>
              <p>
                Foglaljon időpontot, kérjen árajánlatot!
                <br />
                Válassza ki a rendezvény időpontját, helyszínét majd adja meg az elérhetőségét amin keresztül felvehetem Önnel a kapcsolatot.
              </p>
              <p className={classes.important}>FONTOS! Ahhoz hogy fel tudjuk venni a kapcsolatot, elengedhetetlen hogy az elérhetőségeit pontosan adja meg!</p>
            </div>
            <HorizontalRule />
          </Fragment>
        )}

        {formSpace}

        <HorizontalRule />
        <h1>Elérhetőség:</h1>
        <div className={classes.container}>
          <div className={classes.infoItem}>
            <div className={classes.infoIcon}>
              <img src={phone} alt="phone" />
            </div>
            <p>
              Telefonszám: <span>+36 70 / 388 1959</span>
            </p>
          </div>
          <div className={classes.infoItem}>
            <div className={classes.infoIcon}>
              <img src={mail} alt="mail" />
            </div>
            <p>
              E-mail: <span>tuzidomar@gmail.com</span>
            </p>
          </div>
        </div>
        <h1>Social Media:</h1>
        <div className={classes.links}>
          <div className={classes.linkItem}>
            <a href="https://www.facebook.com/tuzidomar/" target="_blank" rel="noopener noreferrer">
              <img src={facebook} alt="facebook" />
              <p>Facebook</p>
            </a>
          </div>
          <div className={classes.linkItem}>
            <a href="https://www.instagram.com/tierra_jimmy/" target="_blank" rel="noopener noreferrer">
              <img src={insta} alt="instagram" />
              <p>Instagram</p>
            </a>
          </div>
          <div className={classes.linkItem}>
            <a href="https://twitter.com/tuzidomar" target="_blank" rel="noopener noreferrer">
              <img src={twitter} alt="twitter" />
              <p>Twitter</p>
            </a>
          </div>
          <div className={classes.linkItem}>
            <a href="https://www.youtube.com/channel/UCqGBTXLFgysm1YqXBgKFTEg" target="_blank" rel="noopener noreferrer">
              <img src={youtube} alt="youtube" />
              <p>YouTube</p>
            </a>
          </div>
        </div>
      </div>
      <HorizontalRule />
      <YouTubeVideo link="https://www.youtube.com/embed/NdWfFTl_62M?autoplay=1&controls=0&loop=1&playlist=NdWfFTl_62M" />
    </div>
  );
}

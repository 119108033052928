import { makeStyles } from '@material-ui/core/styles';
import sizes from 'sizes';

export default makeStyles({
   topNavbar: {
      display: 'flex',
      flexShrink: '1',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#2A2A2A',
      height: '45px',
      width: '100%',
      boxSizing: 'border-box',
      position: 'fixed',
      top: '0',
      left: '0',
      zIndex: '110',
      padding: '0 200px',
      [sizes.down('xl')]: {
         padding: '0 120px'
      },
      [sizes.down('lg')]: {
         padding: '0 70px'
      },
      [sizes.down('md')]: {
         padding: '0 50px'
      },
      [sizes.down('xs')]: {
         padding: '0 30px'
      }
   },
   navbarShadow: {
      [sizes.down('lg')]: {
         boxShadow: '0px -10px 15px 2px rgba(0,0,0,1)',
      }
   },
   logo: {
      '& img': {
         height: '18px',
         width: '87px'
      }
   },
   links: {
      display: 'flex',
      alignItems: 'center',
      '& img': {
         height: '20px',
         width: '39px',
         padding: '0 10px'
      },
      [sizes.down('lg')]: {
         display: 'none'
      }
   },
   navToggler: {
      padding: '0',
      border: '0',
      outline: 'none',
      display: 'none',
      background: 'transparent',
      zIndex: '110',
      '& span': {
         display: 'block',
         background: 'white',
         width: '30px',
         height: '3px',
         borderRadius: '20%',
         marginBottom: '5px',
         transition: 'transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0)'
      },
      '& span:first-child': {
         marginTop: '5px'
      },
      [sizes.down('lg')]: {
         display: 'flex',
         flexDirection: 'column',
         justifyContent: 'center'
      }
   },
   topSpanOpen: {
      transform: 'rotate(45deg) translateX(2.5px) translateY(3px)'
   },
   bottomSpanOpen: {
      transform: 'rotate(-45deg) translateX(2.5px) translateY(-3px)'
   },
   backdrop: {
      position: 'fixed',
      display: 'none',
      top: '0',
      left: '0',
      zIndex: '90',
      width: '0%',
      height: '100vh',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      opacity: '0',
      transition: 'opacity 0.3s ease-out',
      [sizes.down('lg')]: {
         display: 'block'
      }
   },
   backdropOpen: {
      transform: 'translateX(0)',
      width: '100%',
      opacity: '1'
   },
   Navbar: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#202020',
      height: '60px',
      width: '100%',
      position: 'fixed',
      top: '45px',
      left: '0',
      zIndex: '100',
      boxShadow: '0px -10px 15px 0px rgba(0,0,0,1)',
      [sizes.down('lg')]: {
         display: 'none'
      }
   },
   navList: {
      padding: '0',
      listStyle: 'none',
      margin: '0',
      '& li': {
         display: 'inline-flex',
         justifyContent: 'center',
         '& a': {
            color: 'white',
            fontSize: '1rem',
            fontWeight: '700',
            padding: '0px 30px',
            margin: '0 20px',
            letterSpacing: '3px',
            lineHeight: '60px',
            textDecoration: 'none'
         },
         '& a:hover': {
            backgroundColor: '#F18F33',
            transition: 'background 0.2s linear'
         }
      }
   },
   navActive: {
      backgroundColor: '#F18F33',
   }
})
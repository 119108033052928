import React from 'react';
import { Link } from 'react-router-dom';
import ContactButtonStyles from './ContactButtonStyles';

export default function ContactButton() {
   const classes = ContactButtonStyles()
   return (
      <div className={classes.contact}>
         <h4>Kérjen árajánlatot!</h4>
         <Link className={classes.contactButton} to='/kapcsolat'>KAPCSOLAT</Link>
      </div>
   )
}
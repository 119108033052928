import { makeStyles } from '@material-ui/core/styles';
import sizes from 'sizes';

export default makeStyles({
   Contact: {
      width: '100%',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: 'white',
      marginTop: '105px',
      padding: '3rem 0',
      background: 'linear-gradient(#2A2A2A, #202020)',
      [sizes.down('lg')]: {
         marginTop: '45px',
         background: 'linear-gradient(180deg, rgba(32,32,32,1) 0%, rgba(42,42,42,1) 50%, rgba(32,32,32,1) 100%)'
      },
      [sizes.down('xs')]: {
         paddingTop: '1.5rem'
      }
   },
   contactInstructions: {
      [sizes.down('xs')]: {
         textAlign: 'center'
      },
   },
   information: {
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      letterSpacing: '1px',
      zIndex: '1',
      '& hr': {
         width: '80%'
      },
      '& h1': {
         paddingLeft: '10px',
         letterSpacing: '3px',
         fontSize: '24px',
         alignSelf: 'start',
         // textDecoration: 'underline'
      },
      [sizes.down('lg')]: {
         width: '60%'
      },
      [sizes.down('sm')]: {
         width: '70%'
      },
      [sizes.down('xs')]: {
         width: '90%',
         '& h1': {
            alignSelf: 'center'
         }
      }
   },
   important: {
      fontSize: '14px',
      fontWeight: '300',
      fontStyle: 'italic'
   },
   container: {
      width: '100%',
      display: 'flex',
      padding: '1rem 0',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
   },
   infoItem: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      textAlign: 'center',
      fontWeight: '700',
      '& img': {
         padding: '1rem'
      },
      '& span': {
         fontWeight: '400',
         color: '#F18F33'
      },
      [sizes.down('xs')]: {
         flexDirection: 'column'
      }
   },
   infoIcon: {
      width: '6rem'
   },
   links: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      padding: '2rem 0',
      width: '100%',
      [sizes.down('xs')]: {
         flexWrap: 'wrap',
         width: '80%'
      }
   },
   linkItem: {
      display: 'flex',
      justifyContent: 'center',
      '& a': {
         color: 'white',
         textAlign: 'center',
         textDecoration: 'none'
      },
      '& a img': {
         width: '45px',
         height: '45px'
      },
      [sizes.down('xs')]: {
         padding: '1rem 0',
         width: '50%'
      }
   }
})
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Navbar from 'components/Navbar/Navbar';
import Showcase from 'components/Showcase/Showcase';
import HomeShows from 'components/HomeShows/HomeShows';
import About from 'components/About/About';
import Footer from 'components/Footer/Footer';
import Gallery from 'components/Gallery/Gallery';
import Contact from 'components/Contact/Contact';
import Show from 'components/Show/Show';
import Page from 'components/Page/Page';
import ShowStandard from 'components/Show/ShowStandard';
import ShowSpecial from 'components/Show/ShowSpecial';
import ShowPremium from 'components/Show/ShowPremium';
import ShowCostume from 'components/Show/ShowCostume';
import DiagonalSection from 'components/DiagonalSection/DiagonalSection';

export default function App() {
   return (
      <div>
         <Navbar />
         <Switch>
            <Route exact path='/' render={() => (
               <Page>
                  <Showcase title='Tűzidomár'/>
                  <DiagonalSection />
                  <HomeShows />
               </Page>
            )}/>
            <Route exact path='/rolam' render={() => (
               <Page>
                  <About title='Tűzidomár - Rólam'/>
               </Page>
            )}/>
            <Route exact path='/eloadasok' render={() => (
               <Page>
                  <Show title='Tűzidomár - Előadások'/>
               </Page>
            )}/>
            <Route exact path='/eloadasok/standard' render={() => (
               <Page>
                  <ShowStandard title='Tűzidomár - Standard'/>
               </Page>
            )}/>
            <Route exact path='/eloadasok/premium' render={() => (
               <Page>
                  <ShowPremium title='Tűzidomár - Prémium'/>
               </Page>
            )}/>
            <Route exact path='/eloadasok/jelmezes' render={() => (
               <Page>
                  <ShowCostume title='Tűzidomár - Jelmezes'/>
               </Page>
            )}/>
            <Route exact path='/eloadasok/specialis' render={() => (
               <Page>
                  <ShowSpecial title='Tűzidomár - Speciális'/>
               </Page>
            )}/>
            <Route exact path='/galeria' render={() => (
               <Page>
                  <Gallery title='Tűzidomár - Galéria'/>
               </Page>
            )}/>
            <Route exact path='/kapcsolat' render={() => (
               <Page>
                  <Contact title='Tűzidomár - Kapcsolat'/>
               </Page>
            )}/>
            <Route render={() => (
               <Redirect to='/' />
            )}/>
         </Switch>
         <Footer />
      </div>
   );
}

import { makeStyles } from '@material-ui/core/styles';
import sizes from 'sizes';
import about1 from 'assets/images/about/about-1.jpg';
import about2 from 'assets/images/about/about-2.jpg';
import about3 from 'assets/images/about/about-3.jpg';

export default makeStyles({
   About: {
      width: '100%',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: 'white',
      background: 'linear-gradient(#2A2A2A, #202020)',
      padding: '3rem 0',
      marginTop: '105px',
      [sizes.down('lg')]: {
         marginTop: '45px',
         background: 'linear-gradient(180deg, rgba(32,32,32,1) 0%, rgba(42,42,42,1) 50%, rgba(32,32,32,1) 100%)'
      },
      [sizes.down('md')]: {
         padding: '25px 0'
      }
   },
   container: {
      width: '60%',
      zIndex: '1',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      margin: '3rem 0',
      [sizes.down('xl')]: {
         width: '70%'
      },
      [sizes.down('lg')]: {
         width: '80%',
         margin: '2rem 0'
      },
      [sizes.down('sm')]: {
         width: '90%',
         flexDirection: 'column',
         margin: '1rem 0'
      }
   },
   containerReversed: {
      [sizes.down('sm')]: {
         flexDirection: 'column-reverse'
      }
   },
   img: {
      width: '300px',
      height: '300px',
      margin: '0 1rem',
      // borderRadius: '5px',
      backgroundSize: 'cover',
      backgroundPosition: '50% 50%',
      backgroundRepeat: 'no-repeat',
      boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.75)',
      [sizes.down('md')]: {
         height: '360px'
      },
      [sizes.down('sm')]: {
         width: '90%'
      },
   },
   aboutImg1: {
      backgroundImage: `url(${about1})`,
      backgroundPosition: 'top 20% right 50%'
   },
   aboutImg2: {
      backgroundImage: `url(${about2})`,
      [sizes.down('sm')]: {
         backgroundPosition: 'top 20% right 50%'
      }
   },
   aboutImg3: {
      backgroundImage: `url(${about3})`
   },
   text: {
      width: '60%',
      fontWeight: '600',
      fontSize: '1rem',
      letterSpacing: '1px',
      textAlign: 'justify',
      lineHeight: '2rem',
      // textShadow: '3px 3px 10px rgba(0,0,0,0.7)',
      '& a:hover': {
         textDecoration: 'underline'
      },
      '& span': {
         fontStyle: 'italic'
      },
      [sizes.down('xl')]: {
         padding: '0 20px'
      },
      [sizes.down('md')]: {
         fontSize: '14px'
      },
      [sizes.down('sm')]: {
         lineHeight: '22px',
         paddingLeft: '0',
         paddingRight: '0',
         paddingTop: '2rem',
         margin: '0',
         width: '90%'
      }
   },
   link: {
      color: '#F18F33',
      textDecoration: 'none'
   }
})
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
   HomeShows: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '100px 0',
      color: 'white',
      textAlign: 'center',
      background: 'linear-gradient(#2A2A2A, #202020)'
   },
   title: {
      letterSpacing: '5px',
      textTransform: 'uppercase',
      fontWeight: '400'
   },
   hr: {
      width: '70%',
      margin: '20px 0',
      borderColor: 'rgba(0,0,0,0.15)'
   },
   contactButton: {
      cursor: 'pointer',
      color: 'white',
      fontSize: '1.2rem',
      fontWeight: '700',
      letterSpacing: '1px',
      textDecoration: 'none',
      textTransform: 'uppercase',
      textAlign: 'center',
      width: '220px',
      padding: '12px',
      marginTop: '4rem',
      border: '0',
      backgroundColor: '#F18F33',
      outline: 'none',
      boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)'
   },
   heading: {
      color: 'white',
      fontSize: '1rem',
      fontWeight: '400',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      textAlign: 'center',
      width: '200px',
      marginTop: '1.5rem'
   },
})
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../../node_modules/@glidejs/glide/dist/css/glide.core.min.css';
import '../../../node_modules/@glidejs/glide/dist/css/glide.theme.min.css';
import './GliderStyles.css';
import Glide from '@glidejs/glide'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

export default function Glider() {
   useEffect(() => {
      const config = {
         type: 'slider',
         startAt: 0,
         perView: 3,
         gap: 50,
         bound: true,
         breakpoints: {
            1200: {
               perView: 2
            },
            768: {
               perView: 1
            }
         }
      }
      new Glide('.glide', config).mount()
   });
   
   return (
      <div className="glide">
         <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
               <li className="glide__slide">
                  <Link to='/eloadasok/standard'>
                     <div className="glider-standard-img"/>
                  </Link>
                  <h1 className="glider-show-type">Standard</h1>
                  <hr className="glider-hr" />
                  <div className="glider-info">
                     <p>25 perc</p>
                     <Link className="glider-more" to='/eloadasok/standard'>Bővebben</Link>
                  </div>
               </li>
               <li className="glide__slide">
                  <Link to='/eloadasok/premium'>
                     <div className="glider-premium-img"/>
                  </Link>
                  <h1 className="glider-show-type">Prémium</h1>
                  <hr className="glider-hr" />
                  <div className="glider-info">
                     <p>30 perc</p>
                     <Link className="glider-more" to='/eloadasok/premium'>Bővebben</Link>
                  </div>
               </li>
               <li className="glide__slide">
                  <Link to='/eloadasok/jelmezes'>
                     <div className="glider-costume-img"/>
                  </Link>
                  <h1 className="glider-show-type">Jelmezes</h1>
                  <hr className="glider-hr" />
                  <div className="glider-info">
                     <p>25 perc</p>
                     <Link className="glider-more" to='/eloadasok/jelmezes'>Bővebben</Link>
                  </div>
               </li>
               <li className="glide__slide">
                  <Link to='/eloadasok/specialis'>
                     <div className="glider-special-img"/>
                  </Link>
                  <h1 className="glider-show-type">Speciális</h1>
                  <hr className="glider-hr" />
                  <div className="glider-info">
                     <p>Egyéni</p>
                     <Link className="glider-more" to='/eloadasok/specialis'>Bővebben</Link>
                  </div>
               </li>
            </ul>
         </div>
         <div className="glide__arrows" data-glide-el="controls">
            <button className="glide__arrow glide__arrow--left" data-glide-dir="<">
               <ArrowBackIcon />
            </button>
            <button className="glide__arrow glide__arrow--right" data-glide-dir=">">
               <ArrowForwardIcon />
            </button>
         </div>
      </div>
   )
}
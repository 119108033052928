import { makeStyles } from '@material-ui/core/styles';
import sizes from 'sizes';

export default makeStyles({
   Footer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      width: '100%',
      paddingTop: '2rem',
      position: 'relative',
      background: 'linear-gradient(#2A2A2A, #202020)',
      boxShadow: '0px 10px 15px 0px rgba(0,0,0,1)'
   },
   logo: {
      width: '114px',
      height: '24px'
   },
   name: {
      textTransform: 'uppercase',
      fontSize: '14px',
      letterSpacing: '2px',
      fontWeight: '400',
      textShadow: '3px 3px 10px rgba(0,0,0,0.7)'
   },
   hr: {
      width: '80%',
      margin: '20px 0',
      borderColor: 'rgba(0,0,0,0.15)'
   },
   links: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '20%',
      padding: '1rem',
      marginBottom: '1rem',
      '& a img': {
         width: '45px',
         height: '45px'
      },
      [sizes.down('lg')]: {
         width: '30%'
      },
      [sizes.down('sm')]: {
         width: '40%'
      },
      [sizes.down('xs')]: {
         width: '70%'
      }
   }
})
import { makeStyles } from '@material-ui/core/styles';
import sizes from 'sizes';

export default makeStyles({
   promoVideo: {
      width: '50%',
      padding: '3rem 0',
      [sizes.down('lg')]: {
         width: '60%'
      },
      [sizes.down('md')]: {
         width: '70%'
      },
      [sizes.down('sm')]: {
         width: '80%'
      },
      [sizes.down('xs')]: {
         width: '90%'
      }
   },
   ytContainer: {
      position: 'relative',
      width: '100%',
      height: '0',
      paddingBottom: '56.25%',
      boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.75)'
   },
   ytVideo: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
   }
})
import bekes1 from 'assets/images/gallery/bekes-1.jpg';
import bekes3 from 'assets/images/gallery/bekes-3.jpg';
import ketegyhaza1 from 'assets/images/gallery/ketegyhaza-1.jpeg';
import bekescsaba1 from 'assets/images/gallery/bekescsaba-1.jpg';
import bekescsaba2 from 'assets/images/gallery/bekescsaba-2.jpeg';
import bekescsaba3 from 'assets/images/gallery/bekescsaba-3.jpg';
import csorvas1 from 'assets/images/gallery/csorvas-1.jpg';
import csorvas2 from 'assets/images/gallery/csorvas-2.jpg';
import csorvas3 from 'assets/images/gallery/csorvas-3.jpg';
import csorvas4 from 'assets/images/gallery/csorvas-4.jpg';
import gyomaendrod1 from 'assets/images/gallery/gyomaendrod-1.jpg';
import gyomaendrod2 from 'assets/images/gallery/gyomaendrod-2.jpg';
import gyomaendrod3 from 'assets/images/gallery/gyomaendrod-3.jpg';
import gyomaendrod4 from 'assets/images/gallery/gyomaendrod-4.jpg';
import gyomaendrod5 from 'assets/images/gallery/gyomaendrod-5.jpg';
import gyula1 from 'assets/images/gallery/gyula-1.jpg';
import gyula2 from 'assets/images/gallery/gyula-2.jpg';
import gyula3 from 'assets/images/gallery/gyula-3.jpg';


export default [
   {
      original: `${bekes1}`,
      thumbnail: `${bekes1}`,
      description: 'Békés - 2017'
   },
   {
      original: `${csorvas1}`,
      thumbnail: `${csorvas1}`,
      description: 'Csorvás - 2018'
   },
   {
      original: `${csorvas2}`,
      thumbnail: `${csorvas2}`,
      description: 'Csorvás - 2018'
   },
   {
      original: `${csorvas3}`,
      thumbnail: `${csorvas3}`,
      description: 'Csorvás - 2018'
   },
   {
      original: `${csorvas4}`,
      thumbnail: `${csorvas4}`,
      description: 'Csorvás - 2018'
   },
   {
      original: `${ketegyhaza1}`,
      thumbnail: `${ketegyhaza1}`,
      description: 'Kétegyháza - 2019'
   },
   {
      original: `${bekescsaba1}`,
      thumbnail: `${bekescsaba1}`,
      description: 'Békéscsaba - 2019'
   },
   {
      original: `${bekescsaba2}`,
      thumbnail: `${bekescsaba2}`,
      description: 'Békéscsaba - 2015'
   },
   {
      original: `${bekescsaba3}`,
      thumbnail: `${bekescsaba3}`,
      description: 'Békéscsaba - 2016'
   },
   {
      original: `${gyula1}`,
      thumbnail: `${gyula1}`,
      description: 'Gyula - 2018'
   },
   {
      original: `${gyula2}`,
      thumbnail: `${gyula2}`,
      description: 'Gyula - 2018'
   },
   {
      original: `${gyula3}`,
      thumbnail: `${gyula3}`,
      description: 'Gyula - 2018'
   },
   {
      original: `${gyomaendrod1}`,
      thumbnail: `${gyomaendrod1}`,
      description: 'Gyomaendrőd - 2018'
   },
   {
      original: `${gyomaendrod2}`,
      thumbnail: `${gyomaendrod2}`,
      description: 'Gyomaendrőd - 2018'
   },
   {
      original: `${gyomaendrod3}`,
      thumbnail: `${gyomaendrod3}`,
      description: 'Gyomaendrőd - 2018'
   },
   {
      original: `${gyomaendrod4}`,
      thumbnail: `${gyomaendrod4}`,
      description: 'Gyomaendrőd - 2018'
   },
   {
      original: `${gyomaendrod5}`,
      thumbnail: `${gyomaendrod5}`,
      description: 'Gyomaendrőd - 2018'
   },
   {
      original: `${bekes3}`,
      thumbnail: `${bekes3}`,
      description: 'Békés - 2016'
   },
];
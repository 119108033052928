import { makeStyles } from '@material-ui/core/styles';
import sizes from 'sizes';
import standard1 from 'assets/images/shows/show-standard-1.jpg';
import standard2 from 'assets/images/shows/show-standard-2.jpg';
import standard3 from 'assets/images/shows/show-standard-3.jpg';
import premium1 from 'assets/images/shows/show-premium-1.jpg';
import premium2 from 'assets/images/shows/show-premium-2.jpg';
import premium3 from 'assets/images/shows/show-premium-3.jpg';
import costume1 from 'assets/images/shows/show-costume-1.jpg';
import costume2 from 'assets/images/shows/show-costume-2.jpg';
import costume3 from 'assets/images/shows/show-costume-3.jpg';
import special from 'assets/images/shows/show-special-1.jpg';
import './ShowAnimation.css';

export default makeStyles({
   ShowTypePage: {
      width: '100%',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: 'white',
      padding: '4rem 0',
      marginTop: '105px',
      background: 'linear-gradient(#2A2A2A, #202020)',
      [sizes.down('lg')]: {
         marginTop: '45px',
         background: 'linear-gradient(180deg, rgba(32,32,32,1) 0%, rgba(42,42,42,1) 50%, rgba(32,32,32,1) 100%)'
      },
      [sizes.down('xs')]: {
         padding: '0 0 3rem 0'
      }
   },
   costumeShowType: {
      color: '#FFC300',
      fontStyle: 'italic'
   },
   specialShowType: {
      color: '#FFC300 !important'
   },
   italic: {
      fontStyle: 'italic'
   },
   showBody: {
      width: '60%',
      zIndex: "1",
      fontWeight: '600',
      fontSize: '1rem',
      letterSpacing: '1px',
      textAlign: 'justify',
      lineHeight: '1.5rem',
      // textShadow: '3px 3px 10px rgba(0,0,0,0.7)',
      '& h1': {
         color: '#F18F33',
         letterSpacing: '2px'
      },
      '& h2': {
         color: '#FFC300',
         margin: '2.5rem',
         textAlign: 'center',
         fontSize: '1.3rem'
      },
      '& h3': {
         color: '#F18F33',
         marginTop: '1.5rem',
         letterSpacing: '2px'
      },
      [sizes.down('xl')]: {
         width: '70%'
      },
      [sizes.down('lg')]: {
         width: '80%'
      },
      [sizes.down('md')]: {
         fontSize: '14px'
      },
      [sizes.down('sm')]: {
         width: '90%'
      },
      [sizes.down('xs')]: {
         '& h1': {
            textAlign: 'center',
            marginTop: '300px'
         },
         '& p': {
            padding: '1rem'
         },
         '& h3': {
            padding: '0 1rem'
         }
      }
   },
   list: {
      textAlign: 'left',
      [sizes.down('xs')]: {
         '& ul': {
            paddingRight: '1rem'
         }
      }
   },
   imageList: {
      position: 'relative',
      width: '300px',
      height: '300px',
      marginRight: '2rem',
      marginBottom: '1rem',
      marginTop: '1rem',
      padding: '0',
      // borderRadius: '5px',
      float: 'left',
      listStyle: 'none',
      backgroundColor: 'rgba(0,0,0,0.8)',
      boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.75)',
      '& li': {
         width: '100%',
         height: '100%',
         position: 'absolute',
         top: '0',
         left: '0',
         opacity: '0',
         // borderRadius: '5px',
         animation: 'ShowAnimation 30s linear infinite'
      },
      [sizes.down('md')]: {
         width: '250px',
         height: '250px'
      },
      [sizes.down('sm')]: {
         width: '200px',
         height: '200px'
      },
      [sizes.down('xs')]: {
         width: '100%',
         height: '250px',
         marginRight: '0',
         marginTop: '0',
         boxShadow: '0px 7px 7px 0px rgba(0,0,0,0.6)',
         // borderRadius: '0',
         position: 'absolute',
         top: '0',
         left: '0'
      }
   },
   showImg: {
      width: '300px',
      height: '300px',
      marginRight: '2rem',
      marginBottom: '1rem',
      marginTop: '1rem',
      // borderRadius: '5px',
      float: 'left',
      boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.75)',
      [sizes.down('md')]: {
         width: '250px',
         height: '250px'
      },
      [sizes.down('sm')]: {
         width: '200px',
         height: '200px'
      },
      [sizes.down('xs')]: {
         width: '100%',
         height: '250px',
         marginRight: '0',
         marginTop: '0',
         boxShadow: '0px 7px 7px 0px rgba(0,0,0,0.6)',
         // borderRadius: '0',
         position: 'absolute',
         top: '0',
         left: '0'
      },
   },
   standard1: {
      background: `url(${standard1}) center/cover`
   },
   standard2: {
      background: `url(${standard2}) center/cover`,
      animationDelay: '10s !important'
   },
   standard3: {
      background: `url(${standard3}) center/cover`,
      animationDelay: '20s !important'
   },
   premium1: {
      background: `url(${premium1}) center/cover`
   },
   premium2: {
      background: `url(${premium2}) center/cover`,
      animationDelay: '10s !important'
   },
   premium3: {
      background: `url(${premium3}) center/cover`,
      animationDelay: '20s !important'
   },
   costume1: {
      background: `url(${costume1}) center/cover`
   },
   costume2: {
      background: `url(${costume2}) center/cover`,
      animationDelay: '10s !important'
   },
   costume3: {
      background: `url(${costume3}) center/cover`,
      animationDelay: '20s !important'
   },
   showSpecialImg: {
      background: `url(${special}) center/cover no-repeat`
   }
})
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './ContactFormStyles';
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import './DatePickerStyles.css';
import { registerLocale } from  "react-datepicker";
import hu from 'date-fns/locale/hu';
registerLocale('hu', hu)

class ContactForm extends Component {
   state = {
      showLocation: '',
      showType: 'Standard',
      phoneNumber: '',
      name: '',
      email: '',
      message: '',
      startDate: new Date(),
   }
   
   recaptchaChange = (value) => {
      // console.log("Captcha value:", value);
   }
   
   handleChange = (evt) => {
      this.setState({[evt.target.id]: evt.target.value})
   }
   
   handleSelect = (evt) => {
      this.setState({showType: evt.target.value})
   }
   
   handleDateChange = date => {
      this.setState({
         startDate: date
      });
   }
   
   handleSubmit = (evt) => {
      evt.preventDefault()
      
      const templateParams = {
         showLocation: `${this.state.showLocation}`,
         showType: `${this.state.showType}`,
         name: `${this.state.name}`,
         email: `${this.state.email}`,
         phone: `${this.state.phoneNumber}`,
         message: `${this.state.message}`,
         date: `${this.state.startDate}`
      };
      
      this.sendMail(templateParams)
   }
   
   sendMail = (templateParams) => {
      emailjs.send('contact_service', 'contact_form', templateParams, 'user_1GMEql4Nwj0n21ubl4qRs')
      .then((result) => {
         // console.log(result)
         if(result.status === 200) {
            window.scrollTo(0,0)
            this.props.sentMail()
         } else {
            // console.log('Something went wrong...')
         }
      }, (error) => {
         //  console.log(error.text)
      });
   }
   
   
   render() {
      const { classes } = this.props;
      return (
         <form className={classes.ContactForm} onSubmit={this.handleSubmit}>
            <div className={classes.datePickerContainer}>
               <h2>Dátum:</h2>
               <DatePicker
                  selected={this.state.startDate}
                  minDate={new Date()}
                  onChange={this.handleDateChange}
                  placeholderText="Rendezvény időpontja"
                  dateFormat="yyyy/MM/dd"
                  locale="hu"
                  onFocus={(e) => e.target.readOnly = true}
               />
            </div>
            
            <label htmlFor="showLocation">Helyszín:</label>
            <input id="showLocation" type="text" placeholder="Rendezvény helyszíne" autoComplete="off" onChange={this.handleChange} required/>
            
            <label htmlFor="showType">Előadás Típus:</label>
            <Select 
               labelId="showType"
               disableUnderline
               value={this.state.showType}
               onChange={this.handleSelect}
               className={classes.selectComponent}
               MenuProps={{ disableScrollLock: true }}
               classes={{
                  root: classes.selectRoot,
                  selectMenu: classes.selectMenu,
                  icon: classes.selectIcon
               }} 
            >
               <MenuItem value='Standard'>Standard előadás</MenuItem>
               <MenuItem value='Premium'>Prémium előadás</MenuItem>
               <MenuItem value='Jelmezes'>Jelmezes előadás</MenuItem>
               <MenuItem value='Specialis'>Speciális előadás</MenuItem>
            </Select>
            
            <label htmlFor="name">Név:</label>
            <input id="name" type="text" placeholder="Kapcsolattartó neve" autoComplete="off" onChange={this.handleChange} required/>
            
            <label htmlFor="phoneNumber">Telefonszám:</label>
            <input id="phoneNumber" type="text" placeholder="Kapcsolattartó telefonszáma" autoComplete="off" onChange={this.handleChange} required/>
            
            <label htmlFor="email">E-mail:</label>
            <input id="email" type="email" placeholder="Kapcsolattartó e-mail címe" autoComplete="off" onChange={this.handleChange} required/>
            
            <label htmlFor="message">Megjegyzés:</label>
            <textarea id="message" placeholder="Egyéb megjegyzés..." onChange={this.handleChange} maxlength="300"></textarea>
            
            <ReCAPTCHA
               sitekey="6Ldsct0UAAAAAKz75BQaDlqL46GYRpLJ-VPsymNV"
               onChange={this.recaptchaChange}
               theme="dark"
            />
            
            <button className={classes.submitBtn} type="submit">Küldés</button>
         </form>
      )
   }
}

export default withStyles(styles)(ContactForm);
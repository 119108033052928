import { makeStyles } from '@material-ui/core/styles';
import sizes from 'sizes';

export default makeStyles({
   SideNav: {
      display: 'none',
      position: 'fixed',
      top: '0',
      right: '0',
      background: 'linear-gradient(#2A2A2A, #202020)',
      height: '100vh',
      width: '60%',
      transform: 'translateX(100%)',
      transition: 'transform 0.3s ease-out',
      zIndex: '100',
      [sizes.down('lg')]: {
         display: 'block',
         width: '40%',
      },
      [sizes.down('md')]: {
         width: '50%',
      },
      [sizes.down('sm')]: {
         width: '60%',
      }
   },
   navList: {
      padding: '0',
      margin: '100px 0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      listStyle: 'none',
      '& li': {
         display: 'block',
         padding: '1rem 0',
         '& a': {
            color: 'white',
            fontSize: '13px',
            fontWeight: '700',
            padding: '5px 30px',
            letterSpacing: '2px',
            borderRadius: '5px',
            textDecoration: 'none',
         }
      },
   },
   openSideNav: {
      transform: 'translateX(0)',
      boxShadow: '-2px 0px 15px 0px rgba(0,0,0,0.60)'
   }
})
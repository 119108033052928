import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import clsx from 'clsx';
import NavbarStyles from './NavbarStyles';
import logo from 'assets/images/icons/logo-navbar.png';
import Hu from 'assets/images/Hu.png';
import SideNav from 'components/SideNav/SideNav';

export default function Navbar() {
   const classes = NavbarStyles()
   const [sideNavActive, setSideNavActive] = useState(false)
   
   const handleToggle = () => {
      setSideNavActive(!sideNavActive)
   }
   
   return (
      <div>
         <nav className={clsx(classes.topNavbar, {[classes.navbarShadow]: !sideNavActive})}>
            <div className={classes.logo}>
               <Link to='/'>
                  <img src={logo} alt="tuzidomar" />
               </Link>
            </div>
            <div className={classes.links}>
               <img src={Hu} alt="icon" />
            </div>
            <button className={classes.navToggler} onClick={handleToggle}>
               <span className={sideNavActive ? classes.topSpanOpen : undefined}></span>
               <span className={sideNavActive ? classes.bottomSpanOpen : undefined}></span>
            </button>
         </nav>
         
         <SideNav sideNavActive={sideNavActive} handleToggle={handleToggle}/>
         <div className={clsx(classes.backdrop, {[classes.backdropOpen]: sideNavActive})} onClick={handleToggle}/>
         
         <nav className={classes.Navbar}>
            <ul className={classes.navList}>
               <li>
                  <NavLink exact={true} activeClassName={classes.navActive} to='/'>KEZDŐLAP</NavLink>
               </li>
               <li>
                  <NavLink activeClassName={classes.navActive} to='/rolam'>RÓLAM</NavLink>
               </li>
               <li>
                  <NavLink activeClassName={classes.navActive} to='/eloadasok'>ELŐADÁSOK</NavLink>
               </li>
               <li>
                  <NavLink activeClassName={classes.navActive} to='/galeria'>GALÉRIA</NavLink>
               </li>
               <li>
                  <NavLink activeClassName={classes.navActive} to='/kapcsolat'>KAPCSOLAT</NavLink>
               </li>
            </ul>
         </nav>
      </div>
   )
}
import sizes from 'sizes';

export default {
   ContactForm: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& label': {
         fontSize: '1.3rem',
         fontWeight: '700',
         letterSpacing: '2px',
         padding: '10px',
         [sizes.down('sm')]: {
            fontSize: '1.1rem'
         },
      },
      '& input': {
         boxSizing: 'border-box',
         fontSize: '1.2rem',
         width: '50%',
         padding: '10px 15px',
         marginBottom: '20px',
         border: '0',
         borderRadius: '5px',
         outline: 'none',
         [sizes.down('xl')]: {
            width: '60%'
         },
         [sizes.down('md')]: {
            width: '80%'
         },
         [sizes.down('sm')]: {
            fontSize: '1rem'
         },
      },
      '& input:focus, textarea:focus': {
         boxShadow: '0 0 0px 2px #F18F33'
      },
      '& textarea': {
         boxSizing: 'border-box',
         fontFamily: 'Roboto, sans-serif',
         fontSize: '1.2rem',
         width: '50%',
         height: '100px',
         padding: '10px 15px',
         border: '0',
         borderRadius: '5px',
         outline: 'none',
         resize: 'none',
         marginBottom: '2rem',
         [sizes.down('xl')]: {
            width: '60%'
         },
         [sizes.down('md')]: {
            width: '80%'
         },
         [sizes.down('sm')]: {
            fontSize: '1rem',
            height: '50px'
         },
      }
   },
   selectComponent: {
      width: '50%',
      marginBottom: '20px',
      [sizes.down('xl')]: {
         width: '60%'
      },
      [sizes.down('md')]: {
         width: '80%'
      }
   },
   selectRoot: {
      fontSize: '1.2rem',
      padding: '12px 15px',
      backgroundColor: 'white',
      borderRadius: '5px',
      border: 'none',
      '&:focus': {
         borderRadius: '5px',
         backgroundColor: 'white',
         boxShadow: '0 0 0px 2px #F18F33'
      },
      [sizes.down('sm')]: {
         fontSize: '1rem',
         padding: '10px 15px',
      },
   },
   datePickerContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& h2': {
         fontSize: '1.3rem',
         padding: '10px',
         margin: '0',
         [sizes.down('sm')]: {
            fontSize: '1.1rem'
         }
      }
   },
   submitBtn: {
      cursor: 'pointer',
      textTransform: 'uppercase',
      color: 'white',
      fontSize: '1.2rem',
      fontWeight: '700',
      letterSpacing: '1px',
      width: '140px',
      padding: '10px',
      margin: '40px 0',
      border: '0',
      backgroundColor: '#F18F33',
      outline: 'none',
      boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)'
   }
}
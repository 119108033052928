import React, { useEffect } from 'react';
import '../../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import GalleryStyles from './GalleryStyles';
import ImageGallery from 'react-image-gallery';
import HorizontalRule from 'components/HorizontalRule/HorizontalRule';
import Background from 'components/Background/Background';
import YouTubeVideo from 'components/YouTubeVideo/YouTubeVideo';
import images from './GalleryImages';
import './ImageGalleryStyles.css';

export default function Gallery(props) {
   const classes = GalleryStyles()
   
   useEffect(() => {
      document.title = props.title;
   });
   
   return (
      <div className={classes.Gallery}>
         <Background />
         <div className={classes.imgGalleryContainer}>
            <ImageGallery 
               items={images}
               showFullscreenButton={false}
               showBullets={true}
            />
         </div>
         <HorizontalRule />
         <YouTubeVideo link="https://www.youtube.com/embed/mNxOZGY3ASo" />
         <HorizontalRule />
         <YouTubeVideo link="https://www.youtube.com/embed/j9enudjBt7I" />
         <HorizontalRule />
         <YouTubeVideo link="https://www.youtube.com/embed/hn5ilqy1wTk" />
      </div>
   )
}
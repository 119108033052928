import React, { useEffect } from 'react';
import ShowSharedStyles from './ShowSharedStyles';
import ContactButton from 'components/ContactButton/ContactButton'
import Background from 'components/Background/Background';
import clsx from 'clsx';

export default function ShowSpecial(props) {
   const classes = ShowSharedStyles()
   
   useEffect(() => {
      document.title = props.title;
   });
   
   return (
      <div className={classes.ShowTypePage}>
         <Background />
         <div className={classes.showBody}>
            <div className={clsx(classes.showImg, classes.showSpecialImg)} />
            <h1>Speciális előadás</h1>
            <p>A Speciális előadás oldal többek között azért is jött létre, hogy amennyiben úgy gondolja az Ön megrendelése eltér az alapvető fellépési formáktól, akkor meg tudja írni nekünk mi lenne az Ön elképzelése. Mi azonnal reagálunk a megkeresésre. A kapcsolat menüpontnál tud velünk E-mail vagy telefon formájában kommunikálni.</p>
            <p>Itt láthat pár megvalósult projektet ami gond nélkül működik, de mégis egészen más mint egy normál műsor.</p>
            <h3 className={classes.specialShowType}>Felvonulás Vezetése:</h3>
            <p>Bármely felvonulás élén egy különleges személy a legideálisabb, hogy felhívja a figyelmet. Fáklyás felvonuláson például kézenfekvő a tűzzsonglőr személye, ugyanakkor a folyamatos tempó mellett az eszközök váltása és a dinamikus előadás megőrzése különösen emberpróbáló feladat. Azonban sok éves tapasztalattal és kiváló fizikai erőléttel ez is gond nélkül kivitelezhető az út során. Több mint 5 éve a <span className={classes.italic}>Békéscsabai Garabonciás Napok</span> visszajáró vendégeként volt már alkalmam vezetni jó néhány ilyen felvonulást.</p>
            <p>Kiváló plusz show elem, mely az egybegyűltek figyelmét méginkább felhívja. Bátran ajánlom a műsorom, mely szinte univerzális, így bármilyen felvonulásra alkalmas.</p>
            <h3 className={classes.specialShowType}>Koncert Show:</h3>
            <p>Együttes, vagy bármilyen színpadi formáció plusz show eleme lehet egy tűzzsonglőr. Kiváló hangulatfokozó a legismertebb dal közben egy hatalmas lángcsóva, amit nem gépek hoznak létre, hanem az is élő előadás, nem mellesleg igazán különleges és egyedi. Akár a színpadon, vagy egy minimum 5m X 5m-es, külön felállított emelvényen is megvalósítható. A látványosság megőrzése végett 4-5 dal (átlagosan 4 perc/dal) alatt érdemes a tűzzsonglőr előadást szünetekkel megvalósítani.</p>
            <ContactButton />
         </div>
      </div>
   )
}
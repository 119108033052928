import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import sizes from 'sizes';

const useStyles = makeStyles({
   background: {
      position: 'absolute',
      top: '0',
      width: '70%',
      height: '100%',
      boxShadow: '0px 0px 15px 5px rgba(0,0,0,0.7)',
      [sizes.down('xl')]: {
         width: '80%'
      },
      [sizes.down('lg')]: {
         display: 'none'
      }
   }
})

export default function Background() {
   const classes = useStyles();
   return <div className={classes.background} />
}
import React from 'react';
import { Link } from 'react-router-dom';
import HomeShowsStyles from './HomeShowsStyles';
import Glider from 'components/Glider/Glider';

export default function HomeShows() {
   const classes = HomeShowsStyles()
   
   return (
      <div className={classes.HomeShows}>
         <h1 className={classes.title}>Előadások</h1>
         <hr className={classes.hr}/>
         <Glider />
         <Link className={classes.contactButton} to='/kapcsolat'>Árajánlatkérés</Link>
         <h2 className={classes.heading}>Kérjen ingyenesen árajánlatot!</h2>
      </div>
   )
}
import React from 'react';
import YouTubeVideoStyles from './YouTubeVideoStyles'

export default function YouTubeVideo(props) {
   const classes = YouTubeVideoStyles()
   
   return (
      <div className={classes.promoVideo}>
         <div className={classes.ytContainer}>
            <iframe title="promotion video" className={classes.ytVideo} src={props.link} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
         </div>
      </div>
   )
}
import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  MiddleSection: {
    zIndex: "10",
    position: "relative",
    width: "100%",
    height: "200px",
    // background: 'linear-gradient(#2A2A2A, #202020)',
    backgroundColor: "#202020",
    "&::before": {
      content: '""',
      position: "absolute",
      background: "inherit",
      width: "100%",
      height: "200px",
      top: "-50px",
      left: 0,
      transform: "skewY(2deg)",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      background: "inherit",
      width: "100%",
      height: "200px",
      bottom: "-50px",
      left: 0,
      transform: "skewY(-2deg)",
    },
  },
  videosButton: {
    cursor: "pointer",
    color: "white",
    fontSize: "1.2rem",
    fontWeight: "700",
    letterSpacing: "1px",
    textDecoration: "none",
    textAlign: "center",
    position: "absolute",
    top: "-40%",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: "15",
    width: "140px",
    padding: "10px",
    border: "0",
    backgroundColor: "#F18F33",
    outline: "none",
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
  },
  hr: {
    width: "70%",
    borderColor: "rgba(255, 255, 255, 0.2)",
    position: "relative",
    top: "20%",
    zIndex: 10,
  },
  heading: {
    color: "white",
    fontSize: "1rem",
    fontWeight: "400",
    textTransform: "uppercase",
    letterSpacing: "1px",
    textAlign: "center",
    width: "300px",
    position: "relative",
    zIndex: "15",
    top: "40%",
    margin: "0 auto",
  },
  scrollIcon: {
    color: "white",
    fontSize: "1.5rem",
    position: "absolute",
    bottom: "10%",
    left: "50%",
    transform: "translateX(-50%) rotate(90deg)",
    zIndex: "20",
  },
});

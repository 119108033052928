import { makeStyles } from '@material-ui/core/styles';
import sizes from 'sizes';
import standard from 'assets/images/shows/show-standard-1.jpg';
import premium from 'assets/images/shows/show-premium-1.jpg';
import costume from 'assets/images/shows/show-costume-1.jpg';
import special from 'assets/images/shows/show-special-1.jpg';

export default makeStyles({
   Show: {
      width: '100%',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: 'white',
      padding: '3rem 0',
      marginTop: '105px',
      background: 'linear-gradient(#2A2A2A, #202020)',
      [sizes.down('lg')]: {
         marginTop: '45px',
         background: 'linear-gradient(180deg, rgba(32,32,32,1) 0%, rgba(42,42,42,1) 50%, rgba(32,32,32,1) 100%)',
         padding: '0'
      }
   },
   showsContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: '3rem',
      padding: '3rem 0',
      [sizes.down('lg')]: {
         gridTemplateColumns: '1fr',
         width: '100%',
         gridGap: '0',
         padding: '0 0 3rem 0'
      }
   },
   card: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '400px',
      height: '300px',
      position: 'relative',
      transition: 'transform 0.2s ease-in-out',
      boxShadow: '0px 0px 15px 1px rgba(0,0,0,0.75)',
      '&:hover': {
         transform: 'scale(1.03)',
         '& a': {
            opacity: '1',
            transform: 'translateY(0)'
         },
         '& div': {
            opacity: '1'
         },
         '& h1': {
            transform: 'scale(1.1)',
         }
      },
      '& h1': {
         fontSize: '2.5rem',
         letterSpacing: '2px',
         zIndex: '5',
         marginTop: '0',
         textShadow: '3px 3px 10px rgba(0,0,0,0.9)',
         transition: 'transform 0.2s ease-in-out'
      },
      [sizes.down('lg')]: {
         width: '100%',
         height: '350px',
         boxShadow: 'none',
         borderBottom: '2px solid #F18F33',
         '&:hover': {
            transform: 'none'
         },
         '& h1': {
            transform: 'translateY(75%)'
         }
      },
      [sizes.down('md')]: {
         height: '300px'
      },
      [sizes.down('sm')]: {
         height: '250px'
      }
   },
   cardStandard: {
      background: `url(${standard}) center/cover no-repeat`
   },
   cardPremium: {
      background: `url(${premium}) center/cover no-repeat`
   },
   cardCostume: {
      background: `url(${costume}) center/cover no-repeat`
   },
   cardSpecial: {
      background: `url(${special}) center/cover no-repeat`
   },
   backdrop: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      opacity: '0',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      transition: 'opacity 0.2s ease-in-out',
      [sizes.down('lg')]: {
         opacity: '1',
         backgroundColor: 'rgba(32, 32, 32, 0.7)'
      }
   },
   moreBtn: {
      zIndex: '5',
      textTransform: 'uppercase',
      cursor: 'pointer',
      color: 'white',
      fontSize: '1.2rem',
      fontWeight: '700',
      letterSpacing: '1px',
      textDecoration: 'none',
      textAlign: 'center',
      width: '140px',
      marginTop: '1rem',
      padding: '10px',
      border: '0',
      backgroundColor: '#F18F33',
      outline: 'none',
      opacity: '0',
      transform: 'translateY(150%)',
      transition: 'opacity 0.2s ease-in-out, transform 0.2s ease-in-out'
   }
})
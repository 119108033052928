import React from 'react';
import { Link } from 'react-router-dom';
import DiagonalSectionStyles from './DiagonalSectionStyles';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

export default function DiagonalSection() {
   const classes = DiagonalSectionStyles()

   return (
      <div className={classes.MiddleSection}>
         <Link className={classes.videosButton} to='/galeria'>VIDEÓK</Link>
         <hr className={classes.hr}/>
         <h2 className={classes.heading}>Kérjen ingyenesen személyre szabott árajánlatot!</h2>
         <DoubleArrowIcon className={classes.scrollIcon} />
      </div>
   )
}
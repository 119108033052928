import React, { useEffect } from 'react';
import ShowSharedStyles from './ShowSharedStyles';
import ContactButton from 'components/ContactButton/ContactButton';
import Background from 'components/Background/Background';

export default function ShowStandard(props) {
   const classes = ShowSharedStyles()
   
   useEffect(() => {
      document.title = props.title;
   });
   
   return (
      <div className={classes.ShowTypePage}>
         <Background />
         <div className={classes.showBody}>
            <ul className={classes.imageList}>
               <li className={classes.standard1}></li>
               <li className={classes.standard2}></li>
               <li className={classes.standard3}></li>
            </ul>
            <h1>Standard előadás</h1>
            <p>A Standard előadás közel 10 éve a legnépszerűbb és legdinamikusabban fejlődő műsorom. A többi produkcióval ellentétben nincs külön tematikája, úgymond univerzális, ezért szinte minden rendezvényhez jól illik.</p>
            <p>Ez egy kimondottan dinamikus és káprázatba ejtő, zenére megkoreografált, látványos fellépés, melyet professzionális kosztümben, több féle különleges és sokszínű eszközökkel kivitelezünk. A világ számos részén már egyre ritkábban látott veszélyes show elem is felbukkan, ami nem más mint a tűzfújás. Garantáltan 1 tűzfújás szerepel az előadásban, melyet sokan pont a veszélyei miatt nem vállalnak, mivel helytelen használat során könnyen károsíthatja a szervezetet.</p>
            <p>A műsorszám alatt a közönségnek is lehetősége van közelebbről megismerkedni a tűzzel, interaktív módon, mégis biztonságos kereteken belül. A program garantáltan minőségi szórakozást nyújt minden korosztálynak, a gyerekektől az idősekig.</p>
            <p>A tűzzsonglőr műsort azoknak ajánlom, akik felpezsdítenék a közönséget, vagy a már meglévő hangulatot szeretnék tovább fokozni.</p>
            <h2>Időtartam: 25 perc</h2>
            <div className={classes.list}>
               <h3>Műsoromat ajánlom:</h3>
               <ul>
                  <li>Fesztiválokra, koncertekre</li>
                  <li>Város- és falunapokra</li>
                  <li>Művészi Gálákra- Díjátadókra</li>
                  <li>Céges és magán rendezvényekre</li>
                  <li>Esküvőkre</li>
                  <li>Lány- és legénybúcsúkra</li>
                  <li>Iskolai és óvodai rendezvényekre</li>
                  <li>Vásári forgatagokra és bálokra</li>
               </ul>
            </div>
            <div className={classes.list}>
               <h3>TECHNIKAI FELTÉTELEK:</h3>
               <ul>
                  <li>Legalább 6m X 6m-es tiszta akadálymentes, csúszásmentes terület</li>
                  <li>Kihangosítás az aláfestő zenéhez (nagyteljesítményű aktív hangfal, mp3 lejátszó)</li>
                  <li>Zárható öltöző tisztálkodási lehetőséggel</li>
                  <li>Épületben (bel térben) tartandó rendezvény esetén:
                     <ul>
                        <li>Minimum 6m belmagasság</li>
                        <li>Folyamatos szellőztetés, elszívó rendszer bekapcsolása</li>
                        <li>Tűzjelzők és füstérzékelők kikapcsolása a műsor ideje alatt és az azt követő 10 percben</li>
                     </ul>
                  </li>
               </ul>
            </div>
            <div className={classes.list}>
               <h3>AZ ELŐADÁSHOZ RENDELHETŐ FELÁRAS SZOLGÁLTATÁSAINK:</h3>
               <ul>
                  <li>Hangtechnika (1 db aktív hangfal, állványra telepítve)</li>
                  <li>Lángoló betű, szám kiírás (Talajra rajzolt lángoló betű, szám, egyszerű motívum, csak kültérre!)</li>
               </ul>
            </div>
            <ContactButton />
         </div>
      </div>
   )
}
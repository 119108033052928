import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
   contact: {
      zIndex: '3',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '2rem',
      marginTop: '4rem',
      '& h4': {
         letterSpacing: '2px'
      }
   },
   contactButton: {
      cursor: 'pointer',
      color: 'white',
      fontSize: '1.2rem',
      fontWeight: '700',
      letterSpacing: '1px',
      textDecoration: 'none',
      textAlign: 'center',
      width: '180px',
      padding: '10px',
      border: '0',
      backgroundColor: '#F18F33',
      outline: 'none',
      boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)'
   }
})
import React from "react";
// import './Page.css';

export default function Page({ children }) {
  return (
    <section style={{ backgroundColor: "#202020" }} className="page">
      {children}
    </section>
  );
}

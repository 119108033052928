import React, { useEffect } from 'react';
import ShowSharedStyles from './ShowSharedStyles';
import ContactButton from 'components/ContactButton/ContactButton';
import Background from 'components/Background/Background';

export default function ShowCostume(props) {
   const classes = ShowSharedStyles()
   
   useEffect(() => {
      document.title = props.title;
   });
   
   return (
      <div className={classes.ShowTypePage}>
         <Background />
         <div className={classes.showBody}>
            <ul className={classes.imageList}>
               <li className={classes.costume1}></li>
               <li className={classes.costume2}></li>
               <li className={classes.costume3}></li>
            </ul>
            <h1>Jelmezes előadás</h1>
            <p>Ezen tipusú műsorok közel állnak egy színházi produkcióhoz, azonban megőrzik a dinamikus Standard előadás lendületét. Kifejezetten érdekes és új köntösbe helyezi az igényelt műsorszámot a különböző stílusú jelmez és zenei aláfestés.</p>
            <p><span className={classes.costumeShowType}>Egyiptomi show:</span> A fáraók korabeli Egyiptom fülledt varázsát jeleníti meg a közönség számára. Ezáltal egy egzotikus világ nyílik, melyet elősegít mind a zene, mind a jelmez korhű jellege.</p>
            <p><span className={classes.costumeShowType}>Indián show:</span> Indián zenei aláfestés a hangulat megteremtéséhez. Továbbá a Dél-Amerikából származó jelmez kifinomult részletei méginkább magával ragadják a nagyérdemű közönséget.</p>
            <p><span className={classes.costumeShowType}>Török show:</span> Kiválóan illik ez a műsor korhű fesztiválokra és előadásokra. Jellegzetes jelmez és zenei motívumok jelennek meg a produkció során.</p>
            <p>A tűzzsonglőr műsort azoknak ajánljuk, akik felpezsdítenék a közönséget, vagy a már meglévő hangulatot szeretnék tovább fokozni.</p>
            <p>A műsorszám alatt a közönségnek is lehetősége van közelebbről megismerkedni a tűzzel, interaktív módon, mégis biztonságos kereteken belül. A program garantáltan minőségi szórakozást nyújt minden korosztálynak, a gyerekektől az idősekig.</p>
            <h2>Időtartam: 25 perc</h2>
            <div className={classes.list}>
               <h3>Műsoromat ajánlom:</h3>
               <ul>
                  <li>Fesztiválokra, koncertekre</li>
                  <li>Város- és falunapokra</li>
                  <li>Művészi Gálákra- Díjátadókra</li>
                  <li>Céges és magán rendezvényekre</li>
                  <li>Esküvőkre</li>
                  <li>Lány- és legénybúcsúkra</li>
                  <li>Iskolai és óvodai rendezvényekre</li>
                  <li>Vásári forgatagokra és bálokra</li>
               </ul>
            </div>
            <div className={classes.list}>
               <h3>TECHNIKAI FELTÉTELEK:</h3>
               <ul>
                  <li>Legalább 6m X 6m-es tiszta akadálymentes, csúszásmentes terület</li>
                  <li>Kihangosítás az aláfestő zenéhez (nagyteljesítményű aktív hangfal, mp3 lejátszó)</li>
                  <li>Zárható öltöző tisztálkodási lehetőséggel</li>
                  <li>Épületben (bel térben) tartandó rendezvény esetén:
                     <ul>
                        <li>Minimum 6m belmagasság</li>
                        <li>Folyamatos szellőztetés, elszívó rendszer bekapcsolása</li>
                        <li>Tűzjelzők és füstérzékelők kikapcsolása a műsor ideje alatt és az azt követő 10 percben</li>
                     </ul>
                  </li>
               </ul>
            </div>
            <div className={classes.list}>
               <h3>AZ ELŐADÁSHOZ RENDELHETŐ FELÁRAS SZOLGÁLTATÁSAINK:</h3>
               <ul>
                  <li>Hangtechnika (1 db aktív hangfal, állványra telepítve)</li>
                  <li>Lángoló betű, szám kiírás (Talajra rajzolt lángoló betű, szám, egyszerű motívum, csak kültérre!)</li>
               </ul>
            </div>
            <ContactButton />
         </div>
      </div>
   )
}
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import sizes from 'sizes';

const useStyles = makeStyles({
   rule: {
      width: '40%',
      margin: '20px 0',
      borderColor: 'rgba(0,0,0,0.15)',
      [sizes.down('lg')]: {
         width: '50%'
      },
      [sizes.down('md')]: {
         width: '60%'
      },
      [sizes.down('sm')]: {
         width: '70%'
      },
   },
})

export default function HorizontalRule() {
   const classes = useStyles();
   return <hr className={classes.rule}/>
}
import { makeStyles } from '@material-ui/core/styles';
import sizes from 'sizes';

export default makeStyles({
   Gallery: {
      width: '100%',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      padding: '3rem 0',
      marginTop: '105px',
      background: 'linear-gradient(#2A2A2A, #202020)',
      [sizes.down('lg')]: {
         marginTop: '45px',
         background: 'linear-gradient(180deg, rgba(32,32,32,1) 0%, rgba(42,42,42,1) 50%, rgba(32,32,32,1) 100%)'
      }
   },
   imgGalleryContainer: {
      padding: '3rem 0',
      width: '60%',
      [sizes.down('lg')]: {
         width: '80%'
      },
      [sizes.down('md')]: {
         width: '100%'
      }
   }
})
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AboutStyles from './AboutStyles';
import clsx from 'clsx';
import HorizontalRule from 'components/HorizontalRule/HorizontalRule';
import Background from 'components/Background/Background';
import YouTubeVideo from 'components/YouTubeVideo/YouTubeVideo';

export default function About(props) {
   const classes = AboutStyles()
   
   useEffect(() => {
      document.title = props.title;
   });
   
   return (
      <div className={classes.About}>
         <Background />
         <div className={classes.container}>
            <div className={clsx(classes.img, classes.aboutImg1)}/>
            <p className={classes.text}>
               Tierra Coral Jimmy Gabriel vagyok, tűzzsonglőr. 10 éve foglalkozom ezzel a szakmával. Ez idő alatt sok rendezvényen léptem már fel, és a művésznevemet is egy ilyen fellépés alkalmával kaptam. Egy gyermek megszólított az előadás előtt: <span>"Mikor kezd a tűzidomár?"</span> - Kérdezte és mosolyogva várta a választ. Ekkor még nem volt művésznevem és akkor döbbentem rá, hogy ez a gyermek épp most nevezett el. Azóta mint "Tűzidomár" lépek fel.
            </p>
         </div>
         <HorizontalRule />
         <div className={clsx(classes.container, classes.containerReversed)}>
            <p className={classes.text}>
               Rendkívül elhivatott vagyok a szakmát illetően, ezért folyamatosan képzem magam, így a közönség minden fellépésemen színvonalas előadást láthat, aminek akár a részese is lehet. A műsorok, az eszközök és a jelmezek folyamatosan bővülnek és frissülnek. Változatosság, megújulás és fejlődés, ez a három szempont a legfontosabb számomra.
            </p>
            <div className={clsx(classes.img, classes.aboutImg2)}/>
         </div>
         <HorizontalRule />
         <div className={classes.container}>
            <div className={clsx(classes.img, classes.aboutImg3)}/>
            <p className={classes.text}>
               Ez egy feltörekvő szakma, amivel kevesen foglalkoznak profi szinten. Szívvel lélekkel csinálom hisz ez egy gyönyörű ám nem veszélytelen hivatás. A <Link className={classes.link} to='/galeria'>Galériában</Link> megtekinthetőek azok a városok, rendezvények ahol már jártam és maximális elégedettséggel várnak vissza évről évre. Sok új helyszínnel gyarapodik a felsorolás.
            </p>
         </div>
         <HorizontalRule />
         <YouTubeVideo link="https://www.youtube.com/embed/hkD8rhtnsrI?autoplay=1&controls=0&loop=1&playlist=hkD8rhtnsrI" />
      </div>
   )
}
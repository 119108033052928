import React, { useEffect } from 'react';
import ShowcaseStyles from './ShowcaseStyles';
import facebook from 'assets/images/icons/facebook.png';
import insta from 'assets/images/icons/insta.png';

export default function Showcase(props) {
   const classes = ShowcaseStyles()
   
   useEffect(() => {
      document.title = props.title;
   });
   
   return (
      <div className={classes.Showcase}>
         <div className={classes.overlay}/>
         <ul className={classes.list}>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
         </ul>
         <div className={classes.header}>
            <div>
               <h2>Tierra Jimmy</h2>
               <h1>Tűzzsonglőr <br/>Show</h1>
            </div>
            <div className={classes.links}>
               <a href="https://www.facebook.com/tuzidomar/" target="_blank" rel="noopener noreferrer">
                  <img src={facebook} alt="facebook" />
               </a>
               <a href="https://www.instagram.com/tierra_jimmy/" target="_blank" rel="noopener noreferrer">
                  <img src={insta} alt="instagram" />
               </a>
            </div>
         </div>
      </div>
   )
}
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import sizes from 'sizes';

const useStyles = makeStyles({
   container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '5rem 2rem',
      textAlign: 'center',
      '& h3': {
         color: '#F18F33'
      },
      [sizes.down('sm')]: {
         margin: '3rem 1rem'
      }
   }
})

export default function FormSent(props) {
   const classes = useStyles();
   return (
      <div className={classes.container}>
         <h3>Az ajánlatkérését sikeresen elküldte!</h3>
         <p>A megadott e-mail címre egy automatikus visszaigazoló üzenetet küldtünk!</p>
      </div>
   )
}
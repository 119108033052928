import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import ShowStyles from './ShowStyles';
import HorizontalRule from 'components/HorizontalRule/HorizontalRule';
import Background from 'components/Background/Background';
import YouTubeVideo from 'components/YouTubeVideo/YouTubeVideo';

export default function Show(props) {
   const classes = ShowStyles()
   
   useEffect(() => {
      document.title = props.title;
   });
   
   return (
      <div className={classes.Show}>
         <Background />
         <div className={classes.showsContainer}>
            <div className={clsx(classes.card, classes.cardStandard)}>
               <div className={classes.backdrop}/>
               <h1>Standard</h1>
               <Link className={classes.moreBtn} to='/eloadasok/standard'>Bővebben</Link>
            </div>
            <div className={clsx(classes.card, classes.cardPremium)}>
               <div className={classes.backdrop}/>
               <h1>Premium</h1>
               <Link className={classes.moreBtn} to='/eloadasok/premium'>Bővebben</Link>
            </div>
            <div className={clsx(classes.card, classes.cardCostume)}>
               <div className={classes.backdrop}/>
               <h1>Jelmezes</h1>
               <Link className={classes.moreBtn} to='/eloadasok/jelmezes'>Bővebben</Link>
            </div>
            <div className={clsx(classes.card, classes.cardSpecial)}>
               <div className={classes.backdrop}/>
               <h1>Speciális</h1>
               <Link className={classes.moreBtn} to='/eloadasok/specialis'>Bővebben</Link>
            </div>
         </div>
         <HorizontalRule />
         <YouTubeVideo link="https://www.youtube.com/embed/05OKdEzHt4U?autoplay=1&controls=0&loop=1&playlist=05OKdEzHt4U" />
      </div>
   )
}